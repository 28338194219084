// components/NavigationBar.tsx
import React, { useContext } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { UserContext } from '../UserContext'; // Import UserContext
import usernameIcon from '../assets/username.png';
import loginIcon from '../assets/login.png';

const NavigationBar: React.FC = () => {
  const { user } = useContext(UserContext); // Use UserContext

  return (
    <Navbar expand="lg" className="morfelix_blue">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="row nobsgutter">
        <Nav className="no_link_decoration2 limitmaxwidth">
          {/* <div className="col-2"></div> */}
          <LinkContainer to="/" className="logo col-3">
            <a>Morfelix</a>
          </LinkContainer>
          {/* <LinkContainer to="/projects" className="col-5 projectslink">
            <a><i className="fa fa-layer-group pr-1"></i> Projects</a>
          </LinkContainer> */}
          { user ? 
            <LinkContainer to="/projects" className="col-2 projectslink">
              <a><i className="fa fa-layer-group pr-1"></i> Projects</a>
            </LinkContainer>
            :
            <div className="col-2"></div>
          }
          <div className="col-7" style={{display: 'flex', justifyContent: 'flex-end'}}>
            <div>
              { user ? 
                <LinkContainer to="/profile">
                  <Nav.Link>
                    <img src={usernameIcon} alt="username" className="username-icon" width="30" height="30" style={{margin: 'auto', display: 'block'}} />
                    <div className="navtext username">
                      <span>{user.username}</span>
                      {/* <i className="fa fa-caret-down pl-1"></i> */}
                    </div>
                    
                  </Nav.Link>
                </LinkContainer>
                :
                <LinkContainer to="/login">
                  <Nav.Link>
                    <img src={loginIcon} alt="login" className="login-icon" width="30" height="30" />
                    <div className="navtext">Log in</div>
                  </Nav.Link>
                </LinkContainer>
              }
            </div>
          </div>
      </Nav>
      </Navbar.Collapse>

    </Navbar>
  );
};

export default NavigationBar;