import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { UserProfile, fetchProfile, apiCall, getHours } from '../utils';
import config from '../config';

const stripeProdPromise = loadStripe('pk_live_51Oo7OBJPZmOZYvXB8z8Rp1KtAUs8TwEJxs8Me9wW4DBToUvjmERO1eJIFQPgDZYjCCaIG6EIwjwMgiV22LGh5Kgi00N85ZSxJr');
const stripeTestPromise = loadStripe('pk_test_51Oo7OBJPZmOZYvXBk5INjq1idOL1znSI5UsgVwDJRTird0eeGHzVHiJHPC4ixPPqsXVbsTxKoAUxqPPxoz57Xj4K00cI6X9vGN');

const stripePromise = {
    stripePromise: window.location.origin === 'http://localhost:3000' ? stripeTestPromise : stripeProdPromise,
};

const REQUIREOLDPASSWORD = false;

const Profile: React.FC = () => {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [institute, setInstitute] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');

  const handlePasswordReset = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPasswordMessage('');

    if (newPassword !== confirmPassword) {
      setPasswordMessage('New passwords do not match.');
      return;
    }

    let status = await apiCall('reset-password-profile', 
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          current_password: currentPassword,
          new_password: newPassword,
        })
      },
      (data: any) => {
        console.log(data);
        setPasswordMessage('Password updated successfully.');
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
      }
    );
    console.log(status);

    if (status === 400) {
      setPasswordMessage('Current password is incorrect.');
    }

    // scroll to bottom
    window.scrollTo(0, document.body.scrollHeight);

    
  };

  useEffect(() => {
    const fetch = async () => {
      const profile = await fetchProfile();
      setUserProfile(profile);
      setInstitute(profile?.institute || '');
      if (profile?.remaining_submissions === -1) {
        setBtnDisabled(true);
      }
    };
    fetch();
  }, []);

  const handleCheckout = async () => {
    const stripe = await stripePromise.stripePromise;
    if (userProfile === null) {
      console.error("User profile is null");
      return;
    }
    const response = await fetch(`${config.API_BASE_URL}/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userProfile?.id })
    });
    let session;
    let text = await response.text();
    try {
      session = JSON.parse(text);
    } catch (e) {
      console.log("response was not json and was: ", text)
      return;
    }
    if (stripe === null) {
      console.error("stripe is null");
      return;
    }
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });
    if (result.error) {
      alert(result.error.message);
    }
  };

  const handleCheckoutDebug = async () => {
    apiCall(`debug_premium`,
      {
        method: 'POST',
      },
      () => { },
      false);
  }

  const handleSaveInstitute = async () => {
    if (userProfile === null) {
      console.error("User profile is null");
      return;
    }

    await apiCall(`update-institute`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userProfile.id, institute })
    }, () => {
      setIsEditing(false);
      fetchProfile().then((updatedProfile) => {
        setUserProfile(updatedProfile);
      });
    });
  };

  return (
    <div className="jumbotron">
      <div className="limitmaxwidth py-4" style={{ marginBottom: 100 }}>
        <div>
          <div>
            {userProfile ? (
              <div>
                <h2>Profile page for <strong>{userProfile.username}</strong></h2>
                <p>Email: {userProfile.email}</p>
                <p>Remaining Submissions: {userProfile.remaining_submissions === -1 ? "Unlimited" : getHours(userProfile.remaining_submissions) + " hours (Free accounts get 12 free hours every week)"}</p>
                <p>Premium accounts get unlimited submissions.</p>
                <div style={{ paddingBottom: 20 }}>
                  <b><label>Institute:&nbsp;</label></b>
                  {isEditing ? (
                    <span>
                      <input
                        type="text"
                        value={institute}
                        onChange={(e) => setInstitute(e.target.value)}
                      />&nbsp;
                      <button className="btn btn-primary" onClick={handleSaveInstitute}>Save</button>
                    </span> 
                  ) : (
                    <span>
                      <span>{userProfile.institute}</span>&nbsp;
                      <button className="btn btn-secondary btn-sm" onClick={() => setIsEditing(true)}>Edit</button>
                    </span>
                  )}
                </div>
                <button className="btn btn-primary" onClick={handleCheckout} disabled={btnDisabled} style={{ opacity: 0.3 }}>
                  Upgrade to Premium
                  </button>
                {userProfile.username === "secret_test_account" ? (
                  <button className="btn btn-primary" onClick={handleCheckoutDebug}>Debug Premium</button>
                ) : null}
              </div>
            ) : (
              <p>Loading profile...</p>
            )}
          </div>
        </div>
        <div className="py-3">
          <button className="btn btn-danger" onClick={() => {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            window.location.href = '/';
          }}>Log out</button>
        </div>
        <div className="mt-4">
          <h3>Reset Password</h3>
          <form onSubmit={handlePasswordReset}>

            {!REQUIREOLDPASSWORD ? null : (
              <div className="form-group">
              <label htmlFor="currentPassword">Current Password</label>
              <input
                type="password"
                className="form-control"
                id="currentPassword"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
              />
            </div>
            )}


            <div className="form-group">
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                className="form-control"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm New Password</label>
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">Reset Password</button>
          </form>
          {passwordMessage && <p className="mt-2">{passwordMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default Profile;