import React, { useContext } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { UserContext } from '../UserContext';

const NavigationBar: React.FC = () => {

  return (
    <footer className='morfelix_mediumdark no_link_decoration' style={{position: 'fixed', bottom: 0, width: '100%'}}>


{/* <div className="footercontainer"> © Morfelix </div> */}
<LinkContainer to="/" className="footercontainer"><Nav.Link>© Morfelix</Nav.Link></LinkContainer>
{/* <LinkContainer to="/about" className="footercontainer"><Nav.Link>About Us</Nav.Link></LinkContainer> */}
<LinkContainer to="/terms" className="footercontainer"><Nav.Link>Terms</Nav.Link></LinkContainer>
<div className="footercontainer"> <a href="mailto:support@morfelix.ai">Contact</a> </div>
<LinkContainer to="/faq" className="footercontainer"><Nav.Link>FAQ</Nav.Link></LinkContainer>
    </footer>
  );
};

export default NavigationBar;