import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import Plot from 'react-plotly.js';
import { fetchProfile, getHours } from '../utils';
import { apiCall } from '../utils'; // params are: url: string, requestObject: any, func: any, parseJson = true
import '../ModalAnimations.css';

//@ts-ignore
window.currentlyRefreshing = false;
//@ts-ignore
window.refreshStepsTaken = 0;


interface Submission {
  createdAt: string;
  hash: string;
  name: string;
  size: string;
  plotData: PlotData;
  msg: string; //if it's empty, that means there's no issue
}

interface TripleBarPlotData {
  n1: number;
  n2: number;
  n3: number;
  w: number;
  r: number;
}

interface LinePlotData {
  x: number[];
  y: number[];
}


interface PlotData {
  sleep_state_distribution: TripleBarPlotData;
  bout_duration: number[][];//box plot (3 boxes) size=(3,24)
  number_of_bouts: number[][];//box plot (3 boxes) size=(3,24)
  number_of_transitions: number[][];//box plot (3 boxes) size=(3,24)
  wake_ratio: LinePlotData;
  nrem_ratio: LinePlotData;
  rem_ratio: LinePlotData;
}

interface UploadedFile {
  name: string;
  progress: number;
}

const FolderDetails: React.FC = () => {
  const [downloadingFiles, setDownloadingFiles] = useState<{ [key: string]: boolean }>({});
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

  // const [uploadProgress, setUploadProgress] = useState(0);
  // const [isUploading, setIsUploading] = useState(false);
  const { projectId, folderId } = useParams<{ projectId: string; folderId: string }>();
  const [submissions, setSubmissions] = useState<Submission[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [modalAnimation, setModalAnimation] = useState(false);
  const [selectedSubmissions, setSelectedSubmissions] = useState<string[]>([]);
  const [submissionToDisplay, setSubmissionToDisplay] = useState<Submission>({
    createdAt: '', hash: '', name: '', size: '',
    plotData: {
      sleep_state_distribution: { n1: 0, n2: 0, n3: 0, w: 0, r: 0 },
      bout_duration: [],
      number_of_bouts: [],
      number_of_transitions: [],
      wake_ratio: { x: [], y: [] },
      nrem_ratio: { x: [], y: [] },
      rem_ratio: { x: [], y: [] },
    },
    msg: "",
  });

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
    setTimeout(() => setModalAnimation(true), 10); // Trigger the animation shortly after the modal is set to open
  };

  const closeDeleteModal = () => {
    setModalAnimation(false);
    setTimeout(() => setIsDeleteModalOpen(false), 300); // Delay the removal of the modal from the DOM to allow the animation to play
  };

  const [showFileFormatInfo, setShowFileFormatInfo] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [folderDetails, setFolderDetails] = useState({
    createdAt: '', //'Feb. 12, 2024, 4:23 p.m.',
    inputFiles: 0,
    outputFiles: 0,
  });
  const [remainingSubmissions, setRemainingSubmissions] = useState(0);


  // const [newFolderName, setNewFolderName] = useState('');
  const [loading, setLoading] = useState(true);

  const getRemainingSubmissions = async () => {
    const profile = await fetchProfile();
    if (profile) {
      setRemainingSubmissions(profile.remaining_submissions);
    }
  }

  useEffect(() => {
    fetchFolderDetails();
    startRefreshing();
    getRemainingSubmissions();
    //for testing
    // setUploadedFiles([
    //   { name: 'test1.edf', progress: 50 },
    //   { name: 'test2.edf', progress: 0 },
    // ]);
  }, []);




  const [selectedThreshhold, setSelectedThreshhold] = useState(0.5);
  const updateThreshold = async (newThreshold: number) => {
    try {
      await apiCall(`projects/${projectId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ new_threshold: newThreshold }),
      }, () => {
        console.log('Threshold updated successfully');
      }
      );
    } catch (error) {
      console.error('Error updating threshold', error);
    }
  };
  useEffect(() => {
    // Fetch project details (including threshold) when component mounts
    const fetchThreshold = async () => {
      console.log("fetching threshold");
      apiCall(`projects/${projectId}`, { method: 'GET' }, (data: any) => {
        // Set the threshold from the project details
        setSelectedThreshhold(data.threshold);
        console.log("threshold fetched: ", data.threshold);
      });
    };
    fetchThreshold();
  }, [projectId]);


  const downloadSomething = (data: any, name: string) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name); // Set the file name
    link.setAttribute('target', '_blank'); // Open in new tab 
    document.body.appendChild(link);
    link.click();
    //@ts-ignore
    link.parentNode.removeChild(link); // Clean up
  }

  const [isDownloading, setIsDownloading] = useState(false);
  const downloadSelected = async () => {
    const threshold = selectedThreshhold;
    const data = { submissions: selectedSubmissions, threshold };

    setIsDownloading(true); // Start loading
    try {
      await apiCall(`projects/${projectId}/folders/${folderId}/download_all`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        responseType: 'blob',
      }, (data: any) => {
        downloadSomething(data, `${folderId}_thresholded.zip`);
        setIsDownloading(false); // Stop loading on success
      }, false);
    } catch (error) {
      alert("something went wrong while downloading the files. Please try again later.");
      setIsDownloading(false); // Stop loading on error
    }
  };

  const downloadFile = async (submissionName: string, fileName: string) => {
    if (fileName === "dataframe") {
      fileName = submissionName + "_dataframe.csv"
    }
    setDownloadingFiles(prev => ({ ...prev, [fileName]: true })); // Set the download status to true
    console.log(`Downloading file ${fileName} from submission ${submissionName}`);
    apiCall(`projects/${projectId}/folders/${folderId}/files/${submissionName}/${fileName}`,
      {
        method: 'GET',
        responseType: 'blob',
      },
      (data: any) => {
        downloadSomething(data, fileName);
        setDownloadingFiles(prev => ({ ...prev, [fileName]: false })); // Set the download status to false on success
      },
      false);
  }

  const downloadLabels = async (submissionName: string) => {
    const threshhold = selectedThreshhold;
    const fileName = submissionName + "_dataframe.csv";
    console.log(`Downloading labels for file ${fileName} from submission ${submissionName}`);
    apiCall(`projects/${projectId}/folders/${folderId}/files/${submissionName}/labels/${threshhold}`,
      {
        method: 'GET',
        responseType: 'blob',
      },
      (data: any) => downloadSomething(data, fileName.replace(".edf", "_labels.csv")),
      false);
  }

  const [visualImage, setVisualImage] = useState<string | undefined>(undefined);


  const [plotTabs, setPlotTabs] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState('tab1');

  const openModal = async (submission: Submission) => {
    if (submission.msg === "processing") {
      // If the submission is still processing, don't open the modal
      return;
    }
  
    setSubmissionToDisplay(submission);
    setVisualImage(undefined);
    // Fetch the list of available plot types
    apiCall(
      `projects/${projectId}/folders/${folderId}/files/${submission.name.replace(".edf", "")}/visual_types`,
      { method: 'GET' },
      (response: { plot_types: string[] }) => {
        setPlotTabs(response.plot_types);
        if (response.plot_types.length > 0) {
          fetchVisual(submission.name.replace(".edf", ""), response.plot_types[0]);
        }
      }
    );
    setShowModal(true);
  };

  const fetchVisual = (submissionName: string, plotType: string) => {
    apiCall(
      `projects/${projectId}/folders/${folderId}/files/${submissionName}/visual/${plotType}`,
      { method: 'GET', responseType: 'blob' },
      (data: Blob) => {
        const imageUrl = URL.createObjectURL(data);
        setVisualImage(imageUrl);
      },
      false
    );
  }



  const fetchFolderDetails = async () => {
    apiCall(`projects/${projectId}/folders/${folderId}/submissions`, { method: 'GET' }, (data: any) => {
      // Sort submissions by createdAt date in descending order (newest first)
      const sortedSubmissions = data.submissions.sort((a: Submission, b: Submission) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });

      setSubmissions(sortedSubmissions);
      const date = new Date(data.creation_date);
      let input_files = 0;
      let output_files = 0;
      for (const submission of sortedSubmissions) {
        input_files += 1;
        if (submission.msg === "") {
          output_files += 1;
        }
      }
      setFolderDetails({
        createdAt: date.toLocaleString(),
        inputFiles: input_files,
        outputFiles: output_files,
      });
      setLoading(false);
    });
  }

  const handleSubmissionSelection = (submissionName: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedSubmissions([...selectedSubmissions, submissionName]);
    } else {
      setSelectedSubmissions(selectedSubmissions.filter(name => name !== submissionName));
    }
  };



  const handleDeleteConfirmation = async () => {
    closeDeleteModal();
    const token = localStorage.getItem('token');
    try {
      for (const submissionName of selectedSubmissions) {
        await axios.delete(`${config.API_BASE_URL}/projects/${projectId}/folders/${folderId}/submissions/${submissionName}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      fetchFolderDetails(); // Refresh folder details
      setIsDeleteModalOpen(false);
      setSelectedSubmissions([]);
    } catch (error) {
      console.error('Error deleting submissions', error);
    }
  };


  const startRefreshing = () => {
    //@ts-ignore
    if (!window.currentlyRefreshing) {
      console.log("startRefreshing: window.currentlyRefreshing is false. Starting interval.")
      //@ts-ignore
      window.currentlyRefreshing = true;
      // const intervalId = 
      setInterval(() => {
        //@ts-ignore
        if (window.refreshStepsTaken < 12 * 5) {
          console.log("fast refresh");
          fetchFolderDetails();
          getRemainingSubmissions();
        } else {
          //@ts-ignore
          if (window.refreshStepsTaken % 4 === 0) {
            console.log("slow refresh");
            fetchFolderDetails();
            getRemainingSubmissions();
          }
        }

        //@ts-ignore
        window.refreshStepsTaken += 1;
      }, 5000);

      // // Stop refreshing after 3 minutes
      // setTimeout(() => {
      //   clearInterval(intervalId);
      //   //@ts-ignore
      //   window.currentlyRefreshing = false;
      // }, 180000);
    } else {
      console.log("startRefreshing: window.currentlyRefreshing is true. Not starting interval.")
    }
  }


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const filesArray = Array.from(event.target.files);
      // Add files to uploadedFiles state with initial progress 0
      const content = filesArray.map(file => ({ name: file.name, progress: 0 }));
      console.log("content: ", content);
      setUploadedFiles(content);
      uploadSubmissions(filesArray, 0);
      event.target.value = '';
    }
  };


  const uploadSubmissions = async (files: File[], index: number) => {
    //@ts-ignore
    window.refreshStepsTaken = 0;
    if (index >= files.length) {
      console.log('All files have been uploaded.');
      fetchFolderDetails(); // Refresh folder details after all files are uploaded
      startRefreshing();
      return; // Stop the recursion when all files are uploaded
    }

    const file = files[index];
    // setIsUploading(true); // Start uploading
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('file', file);

    try {
      await axios.post(`${config.API_BASE_URL}/projects/${projectId}/folders/${folderId}/submissions`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent == null) return;
          if (progressEvent.total == null) return;

          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

          console.log(`Uploading ${file.name}: ${percentCompleted}% completed. total: ${progressEvent.total} loaded: ${progressEvent.loaded}`);
          console.log("uploadedFiles: ", uploadedFiles)

          setUploadedFiles(prevUploadedFiles => {
            if (progressEvent.loaded === progressEvent.total) {
              // Remove the uploaded file by name
              return prevUploadedFiles.filter(file => file.name !== files[index].name);
            } else {
              // Update the progress of the current file
              return prevUploadedFiles.map(file =>
                file.name === files[index].name ? { ...file, progress: percentCompleted } : file
              );
            }
          });
        },
      });

      // After the current file is uploaded, proceed to the next one
      // setIsUploading(false); // Finish uploading
      // setUploadProgress(0); // Reset progress



      uploadSubmissions(files, index + 1);
    } catch (error) {
      console.error('Error uploading submission', error);
      // setIsUploading(false); // Finish uploading
      // setUploadProgress(0); // Reset progress
      // Even if an error occurs, try to upload the next file
      uploadSubmissions(files, index + 1);
    }
  };



  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragOver) setIsDragOver(true); // Only update state if necessary
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);

    // Process the dropped files
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const filesArray = Array.from(e.dataTransfer.files);

      // Update uploadedFiles state with dropped files (similar to handleFileChange)
      const content = filesArray.map(file => ({ name: file.name, progress: 0 }));
      setUploadedFiles(content);

      uploadSubmissions(filesArray, 0); // Start uploading files

      // Reset the file input 
      const fileInput = document.getElementById('file-input') as HTMLInputElement;
      if (fileInput) fileInput.value = '';
    }
  };



  const [currentPage, setCurrentPage] = useState(1);
  const submissionsPerPage = 10;
  const [totalPages, setTotalPages] = useState(0);


  useEffect(() => {
    setTotalPages(Math.ceil(submissions.length / submissionsPerPage));
  }, [submissions]);

  const handlePageChange = (direction: 'next' | 'prev') => {
    if (direction === 'next' && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Render only the submissions for the current page
  const indexOfLastSubmission = currentPage * submissionsPerPage;
  const indexOfFirstSubmission = indexOfLastSubmission - submissionsPerPage;
  const currentSubmissions = submissions.slice(indexOfFirstSubmission, indexOfLastSubmission);



  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      // Select all submissions
      const allSubmissionNames = submissions.map(submission => submission.name);
      setSelectedSubmissions(allSubmissionNames);
    } else {
      // Clear selection
      setSelectedSubmissions([]);
    }
  };


  // Update threshold when the slider value changes
  const handleThresholdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newThreshold = parseFloat(e.target.value);
    setSelectedThreshhold(newThreshold);
    updateThreshold(newThreshold); // Update on the backend
  };


  return (
    <div style={{ display: 'flex', flexDirection: 'row' }} className='limitmaxwidth'>
      <div className="py-5 col-12">
        {/* Breadcrumb */}
        <div className="row pb-4">
          <div className="col-12">
            <Link to={`/projects/`}>« back to project page</Link>
          </div>
        </div>
        {/* Folder details */}
        <div className="row">
          <div className="col-8">
            <h4 style={{ wordWrap: 'break-word' }}>
              <i className="fa fa-folder pr-1"></i> {folderId}
            </h4>
            {!loading && (
              <ul className="list-inline small text-muted">
                {/* <li className="list-inline-item">
                Created at {folderDetails.createdAt}
              </li> */}
                <li className="list-inline-item"><i className="fa fa-file-import pr-1"></i>&nbsp;
                  {folderDetails.inputFiles}&nbsp;EDF files.
                </li>
                {/* <li className="list-inline-item"><i className="fa fa-file-export pr-1"></i>&nbsp;
                {folderDetails.outputFiles}&nbsp;output files
              </li> */}
                {remainingSubmissions > 0 && (
                  <li className="list-inline-item">
                    <i className="fa fa-plus-circle pr-1"></i> {getHours(remainingSubmissions)} remaining free hours
                  </li>
                )}
              </ul>
            )}
            {loading && (
              <ul className="list-inline small text-muted">
                <li className="list-inline-item">
                  Loading folder info...
                </li>
              </ul>
            )}
          </div>
        </div>
        <hr />
        {remainingSubmissions !== 0 && (
          <div className="form-group">
            {/* File upload */}
            {/* <h4>File upload</h4> */}
            <div className="upload-area border-dashed btn btn-dark btn-lg text-center"
              onClick={() => document.getElementById('file-input')?.click()}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              style={{ width: '100%', height: '120px', margin: 'auto', display: 'block' }}
            >
              <input id="file-input" type="file" accept=".edf" style={{ display: 'none' }} onChange={handleFileChange} multiple />
              <i className="fa fa-cloud-upload-alt" style={{ marginTop: '22px' }}></i>
              <p>Drop files here to upload</p>
              {/* {isUploading && (
                <div className="progress">
                  <div className="progress-bar" role="progressbar" style={{ width: `${uploadProgress}%` }} aria-valuenow={uploadProgress} aria-valuemin={0} aria-valuemax={100}>{uploadProgress}%</div>
                </div>
              )} */}
            </div>
            <p>
              Before uploading files for scoring, please verify that your
              files are in&nbsp;
              <a
                className='text-primary clickable'
                onClick={() => setShowFileFormatInfo(!showFileFormatInfo)}
                role="button" aria-expanded="false" aria-controls="file-format-info">our expected file format
              </a>.
            </p>
            {/* File info Modal */}
            {showFileFormatInfo && (
              <div id="file-format-info"> {/* className="collapse"  */}
                <div className="alert alert-primary">
                  For the moment, uploaded recordings are assumed to comply with the following specifications:
                  <dl>
                    <dt>File format</dt>
                    <dd>We only accept .edf format in the following two forms:
                      <ul>
                        <li>A 3-channel signal; The first two channels are EEG and the third one is EMG</li>
                        <li>A 2-channel signal; The first channel is EEG and the second one is EMG</li>
                      </ul>
                    </dd>
                    <dt>Sampling rate</dt>
                    <dd>The model is agnostic to the sampling rate.</dd>
                    <dt>Measurement unit</dt>
                    <dd>The model is agnostic to the measurement units.</dd>
                    <dt>File size limit</dt>
                    <dd>500 MB</dd>
                  </dl>
                  You can download a sample file in this format by clicking <a href={`${config.API_BASE_URL}/resources/sample.edf`}>this link</a>.
                </div>
              </div>
            )}
            <hr />
          </div>
        )}
        {remainingSubmissions === 0 && (
          <div className="alert alert-warning">
            Your account has no available submissions. Please upgrade your account to use the service.
          </div>
        )}

        {/* Delete and Upload buttons */}
        <div className="row justify-content-left mb-3">
          <div className="row">

            {remainingSubmissions !== 0 && (
              <div className="col-3">
                {/* <a className="btn btn-success" onClick={() => document.getElementById('file-input')?.click()}>Upload File</a> */}
                <button className="btn btn-success" onClick={() => document.getElementById('file-input')?.click()}>
                  <i className="fa fa-cloud-upload-alt pr-1"></i> Upload file &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </button>
              </div>
            )}

            <div className="col-3">
              <button className="btn btn-light pull-right" disabled={selectedSubmissions.length === 0} onClick={() => openDeleteModal()}>
                <i className="fa fa-trash pr-1"></i> Delete selected
              </button>
            </div>

            <div className="col-3">
              <button className="btn btn-light" disabled={selectedSubmissions.length === 0 || isDownloading} onClick={() => downloadSelected()}>
                {isDownloading ? (
                  <span>
                    <i className="fa fa-spinner fa-spin pr-1"></i> Downloading...
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-download pr-1"></i> Download selected
                  </span>
                )}
              </button>
            </div>

            {/* slider for selecting threshold */}
            {/* <div className="col-3">
                <div className="text-center">
                  <div>Noise threshold:</div>
                  <div>{selectedThreshhold}</div>
                  <div>
                    <input
                      type="range"
                      min="0"
                      max="1"
                      step="0.01"
                      value={selectedThreshhold}
                      onChange={handleThresholdChange}
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              </div> */}
          </div>

        </div>
        {/* Delete Confirmation Modal */}
        {isDeleteModalOpen && (
          <div className={`modal-backdrop ${modalAnimation ? 'show' : ''}`}>
            <div className={`modal-content ${modalAnimation ? 'show' : ''}`}>
              <div className="modal-header">
                <h5 className="modal-title">Are you sure?</h5>
                <button type="button" className="close" onClick={closeDeleteModal}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>This will delete all the selected files (but not the ones that are currently in the middle of processing).</p>
              </div>
              <div className="modal-footer">
                <button className="btn btn-danger" onClick={handleDeleteConfirmation}>
                  <i className="fa fa-trash pr-1"></i> I am sure, delete the files
                </button>
              </div>
            </div>
          </div>
        )}
        {/* Submissions */}
        <div className="row justify-content-center">
          <div className="col-12">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th className="text-left column-artifact-check">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                  </th>
                  <th className="text-left column-artifact-title" style={{ width: '40%' }}>Input EEG file</th>
                  <th className="text-right" style={{ width: '18%' }}><i className="fa fa-clock pr-1"></i> Predictions</th>
                  <th className="text-right" style={{ width: '15%' }}><i className="fa fa-hdd pr-1"></i> Details</th>
                  <th className="text-right" style={{ width: '15%' }}><i className="fas fa-wave-square pr-1"></i> Plots</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                {uploadedFiles.map((file, index) => (
                  <tr key={file.name + "_uploading"}>
                    {/* checkbox and file name cells */}
                    <td className="text-right"></td>
                    <td className="text-left">
                      <span>{file.name}</span>
                    </td>
                    {/* Individual progress bar for each file */}
                    <td className="text-right">
                      <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{ width: `${file.progress}%` }} aria-valuenow={file.progress} aria-valuemin={0} aria-valuemax={100}>{file.progress}%</div>
                      </div>
                    </td>
                    {/* Cells for details, plots, and hash will be empty for upcoming submissions */}
                    <td className="text-right"></td>
                    <td className="text-right"></td>
                  </tr>
                ))}
                {currentSubmissions.map(submission => (
                  <tr key={submission.name}>
                    <td className="column-artifact-check">
                      <input
                        type="checkbox"
                        checked={selectedSubmissions.includes(submission.name)}
                        onChange={(e) => handleSubmissionSelection(submission.name, e.target.checked)}
                      />
                    </td>
                    <td className="column-artifact-title">
                      <a href='#' onClick={(e) => { e.preventDefault(); downloadFile(submission.name.replace(".edf", ""), submission.name) }}>
                        {submission.name}
                        {downloadingFiles[submission.name] && (
                          <span className="ml-2">
                            <i className="fa fa-spinner fa-spin"></i>
                          </span>
                        )}
                      </a>
                      <br />
                      <span className="small text-muted" style={{ fontFamily: 'monospace' }}>{submission.hash}{submission.msg === "error" ? "An error happened while processing the file. The ML server might be broken. Contact us if this keeps happening" : ""}</span>
                    </td>
                    <td className="text-right">
                      {submission.msg === "processing" ? (
                        <div>
                          <div className="spinner-border text-primary" role="status">
                            <span className="sr-only"></span>
                          </div>
                          <span className="text-muted">Processing...</span>
                        </div>
                      ) : submission.msg === "" ? (
                        <div key={submission.name + "_"}>
                          <a href='#' onClick={(e) => { e.preventDefault(); downloadLabels(submission.name.replace(".edf", "")) }}>Download dataframe</a>
                        </div>
                      ) : (
                        <div>
                          {/* {submission.msg === "error" ? "" : "In queue"} */}
                          {submission.msg === "error" ? (
                            <span className="text-muted"></span>
                          ) : (
                            <span className="text-muted">
                              <i className="fa fa-check text-success"></i>In queue
                            </span>
                          )}
                        </div>
                      )}
                    </td>
                    <td className="text-right">
                      <small>{submission.size}</small>
                      <br />
                      <small>{submission.createdAt}</small>
                    </td>
                    <td className="text-right">
                      <button 
                        className="btn clickable" 
                        onClick={() => openModal(submission)}
                        disabled={submission.msg === "processing"}
                      >
                        <i className={`fa fa-chart-bar ${submission.msg === "processing" ? 'text-muted' : 'text-primary'}`}></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Pagination Controls */}
        <div className="pagination-controls">
          <button className="btn btn-light" onClick={() => handlePageChange('prev')} disabled={currentPage === 1}>
            Previous
          </button>
          <span> Page {currentPage} of {totalPages} </span>
          <button className="btn btn-light" onClick={() => handlePageChange('next')} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>

        {/* Plots Modal */}
        <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>{submissionToDisplay.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Tabs for switching between plot sets */}
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`} href="#" onClick={(e) => { e.preventDefault(); setActiveTab('tab1'); }}>Tab 1</a>
              </li>
              {/* <li className="nav-item">
                <a className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`} href="#" onClick={(e) => { e.preventDefault(); setActiveTab('tab2'); }}>Tab 2</a>
              </li> */}
              {plotTabs.map((tab) => (
                <li className="nav-item" key={tab}>
                  <a
                    className={`nav-link ${activeTab === tab ? 'active' : ''}`}
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab(tab);
                      fetchVisual(submissionToDisplay.name.replace(".edf", ""), tab);
                    }}
                  >
                    {tab}
                  </a>
                </li>
              ))}
            </ul>

            {/* Tab Content */}
            <div className="tab-content mt-3">
              {/* First Tab Pane */}
              <div className={`tab-pane fade ${activeTab === 'tab1' ? 'show active' : ''}`}>
                <div className="row">
                  {/* Sleep state distribution plot */}
                  <div className="col-3">
                    <Plot
                      data={[
                        {
                          // x: ['WAKE', 'NREM', 'REM'],
                          // y: [submissionToDisplay.plotData.sleep_state_distribution.w, submissionToDisplay.plotData.sleep_state_distribution.n, submissionToDisplay.plotData.sleep_state_distribution.r],
                          x: ['WAKE', 'N1', 'N2', 'N3', 'REM'],
                          y: [submissionToDisplay.plotData.sleep_state_distribution.w, submissionToDisplay.plotData.sleep_state_distribution.n1, submissionToDisplay.plotData.sleep_state_distribution.n2, submissionToDisplay.plotData.sleep_state_distribution.n3, submissionToDisplay.plotData.sleep_state_distribution.r],
                          type: 'bar',
                          name: 'Sleep State',
                        },
                      ]}
                      layout={{ title: 'Sleep state distribution', xaxis: { title: 'State' }, yaxis: { title: 'Percentage' }, width: 280 }}
                    />
                  </div>
                  {/* Bout duration plot */}
                  <div className="col-3">
                    <Plot
                      data={submissionToDisplay.plotData.bout_duration.map((durations, index) => ({
                        y: durations,
                        type: 'box',
                        name: ['WAKE', 'N1', 'N2', 'N3', 'REM'][index],
                        boxpoints: 'all',
                      }))}
                      layout={{ title: 'Bout duration', xaxis: { title: 'Sleep State' }, yaxis: { title: 'Duration (minutes)' }, width: 280 }}
                    />
                  </div>
                  {/* Number of bouts plot */}
                  <div className="col-3">
                    <Plot
                      data={submissionToDisplay.plotData.number_of_bouts.map((counts, index) => ({
                        y: counts,
                        type: 'box',
                        name: ['WAKE', 'N1', 'N2', 'N3', 'REM'][index],
                        boxpoints: 'all',
                      }))}
                      layout={{ title: 'Number of bouts', xaxis: { title: 'Sleep State' }, yaxis: { title: 'Count' }, width: 280 }}
                    />
                  </div>
                  {/* Number of transitions plot */}
                  <div className="col-3">
                    <Plot
                      data={submissionToDisplay.plotData.number_of_transitions.map((counts, index) => ({
                        y: counts,
                        type: 'box',
                        name: ['W → N', 'N → W', 'N → R', 'R → N'][index],
                        boxpoints: 'all',
                      }))}
                      layout={{ title: 'Number of transitions', xaxis: { title: 'Transition Type' }, yaxis: { title: 'Count' }, width: 280 }}
                    />
                  </div>
                </div>
              </div>
              {/* Second Tab Pane */}
              <div className={`tab-pane fade ${activeTab === 'tab2' ? 'show active' : ''}`}>
                <div className="row">
                  {/* Wake ratio line plot */}
                  <div className="col-md-4">
                    <Plot
                      data={[
                        {
                          x: submissionToDisplay.plotData.wake_ratio.x,
                          y: submissionToDisplay.plotData.wake_ratio.y,
                          type: 'scatter',
                          mode: 'lines+markers',
                          name: 'Wake Ratio',
                        },
                      ]}
                      layout={{ title: 'Wake Ratio Over Time', xaxis: { title: 'Time' }, yaxis: { title: 'Ratio' }, width: 300 }}
                    />
                  </div>
                  {/* NREM ratio line plot */}
                  <div className="col-md-4">
                    <Plot
                      data={[
                        {
                          x: submissionToDisplay.plotData.nrem_ratio.x,
                          y: submissionToDisplay.plotData.nrem_ratio.y,
                          type: 'scatter',
                          mode: 'lines+markers',
                          name: 'NREM Ratio',
                        },
                      ]}
                      layout={{ title: 'NREM Ratio Over Time', xaxis: { title: 'Time' }, yaxis: { title: 'Ratio' }, width: 300 }}
                    />
                  </div>
                  {/* REM ratio line plot */}
                  <div className="col-md-4">
                    <Plot
                      data={[
                        {
                          x: submissionToDisplay.plotData.rem_ratio.x,
                          y: submissionToDisplay.plotData.rem_ratio.y,
                          type: 'scatter',
                          mode: 'lines+markers',
                          name: 'REM Ratio',
                        },
                      ]}
                      layout={{ title: 'REM Ratio Over Time', xaxis: { title: 'Time' }, yaxis: { title: 'Ratio' }, width: 300 }}
                    />
                  </div>
                </div>
              </div>
              {/* Dynamic Tabs for PNG files */}
              {plotTabs.map((tab) => (
                <div key={tab} className={`tab-pane fade ${activeTab === tab ? 'show active' : ''}`}>
                  <div className="row">
                    <div className="col-12 text-center">
                      {activeTab === tab && visualImage && 
                        <img src={visualImage} alt={`${tab} Visualization`} style={{ maxWidth: '100%', maxHeight: '80vh' }} />
                      }
                      {activeTab === tab && !visualImage && <p>Loading visual...</p>} 
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div className="col-2"></div>
    </div>
  );
};

export default FolderDetails;