import React, { useState } from 'react';
import config from '../config';

interface FaqItemProps {
    question: string;
    children: React.ReactNode;
  }
  
  const FaqItem: React.FC<FaqItemProps> = ({ question, children }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <div className="faq-item mb-3">
        <h3 
          className="faq-question" 
          onClick={() => setIsOpen(!isOpen)}
          style={{ cursor: 'pointer' }}
        >
          {question}
          <span className="ml-2">{isOpen ? '▼' : '▶'}</span>
        </h3>
        {isOpen && <div className="faq-answer">{children}</div>}
      </div>
    );
  };


  const Faq: React.FC = () => {
    return (
        <div className="jumbotron">
            <div className="limitmaxwidth py-4 faq">
                <div className="row">
                    <div className="col-md-8">
                        <FaqItem question="Why Morfelix?">
                            <p>Morfelix increases research productivity by speeding up the scoring of sleep data. Morfelix helps researchers to focus on what matters and avoid distractions. It specifically helps researchers with:</p>
                            <ul>
                                <li>Fast and accurate scoring of sleep data,</li>
                                <li>Increasing research reproducibility by standardising data formats and providing stable algorithms for scoring,</li>
                                <li>Providing off-the-shelf algorithm and freeing researchers from implementing every detail by themselves and therefore avoiding mistakes and saving precious research hours.</li>
                            </ul>
                        </FaqItem>

                        {/*<FaqItem question="Which algorithm is being used to score sleep data?">*/}
                        {/*    <p><a href="https://github.com/raphaelvallat/yasa">Yasa</a>.</p>*/}
                        {/*</FaqItem>*/}

                        <FaqItem question="What kind of data format is required for scorings?">
                            <p>
A .edf file of a sleep recording. Here is an <a href="https://drive.google.com/uc?id=13YFQrvAGvcz77Qm8kFXu1nyRKhTti3VB">example recording</a> with 19 EEGs, 2 EOGs, 1 EMG and 1 EKG. For now it's required that at least C4-M1 (or C4-A1) is provided.
                            </p>
                        </FaqItem>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Faq;